'use client'

import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { FaEnvelope, FaRocket, FaShieldAlt } from 'react-icons/fa'
import { z } from 'zod'

const subscribeSchema = z.object({
  email: z.string().email('Please enter a valid email address')
})

type SubscribeFormData = z.infer<typeof subscribeSchema>

type SubscribeResponse = {
  success: boolean
  message: string
}

export function Newsletter() {
  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SubscribeFormData>({
    resolver: zodResolver(subscribeSchema)
  })

  const onSubmit: SubmitHandler<SubscribeFormData> = async data => {
    setIsLoading(true)
    const toastId = toast.loading('Subscribing...')

    try {
      const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      const result: SubscribeResponse = await response.json()

      if (response.ok && result.success) {
        toast.success(result.message, { id: toastId })
        reset()
      } else {
        throw new Error(result.message || 'Subscription failed')
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Error: ${error.message}`, { id: toastId })
      } else {
        toast.error('An unexpected error occurred', { id: toastId })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <section className="bg-background py-20 dark:bg-gray-900">
      <div
        id="newsletter"
        className="relative mx-auto max-w-md overflow-hidden rounded-2xl bg-gradient-to-br from-blue-600 to-purple-700 p-8 shadow-2xl"
      >
        <div className="absolute left-0 top-0 size-full bg-white opacity-5">
          <div className="absolute -left-12 -top-12 size-96 animate-pulse rounded-full bg-white opacity-10"></div>
          <div
            className="absolute -bottom-12 -right-12 size-96 animate-pulse rounded-full bg-white opacity-10"
            style={{ animationDelay: '1s' }}
          ></div>
        </div>
        <div className="relative z-10">
          <div className="mb-6 flex items-center justify-center">
            <FaEnvelope className="text-4xl text-white" />
          </div>
          <h2 className="mb-3 text-center text-3xl font-extrabold text-white">
            Stay Ahead of the Curve
          </h2>
          <p className="mb-6 text-center text-blue-100">
            Get the latest AI insights and tools delivered straight to your inbox!
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="relative">
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  {...register('email')}
                  className="w-full rounded-full bg-white bg-opacity-20 px-4 py-3 pl-10 text-white transition duration-200 placeholder:text-blue-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                  placeholder="Enter your email"
                />
                <FaEnvelope className="absolute left-3 top-1/2 -translate-y-1/2 text-blue-200" />
              </div>
              <div className="mt-2 h-6">
                {errors.email && <p className="text-sm text-red-300">{errors.email.message}</p>}
              </div>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="group flex w-full items-center justify-center rounded-full bg-white px-6 py-3 font-semibold text-blue-600 transition duration-200 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {isLoading ? (
                'Subscribing...'
              ) : (
                <>
                  Subscribe Now
                  <FaRocket className="ml-2 transition-transform duration-200 group-hover:translate-x-1" />
                </>
              )}
            </button>
          </form>
          <div className="mt-6 flex items-center justify-center text-center text-sm text-blue-100">
            <FaShieldAlt className="mr-2" />
            <p>Your data is safe. Unsubscribe anytime.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
