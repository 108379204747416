'use client'

import type { User } from '@prisma/client'
import Link from 'next/link'
import { FaChartLine, FaRocket, FaUsers } from 'react-icons/fa'

import { FeatureCard, type FeatureCardProps } from '~/components/FeatureCard'
import { GradientText } from '~/components/GradientText'

type HeroProps = {
  user: User | null
}

const heroFeatures: FeatureCardProps[] = [
  {
    id: 'active-campaigns-hero',
    icon: FaRocket,
    title: 'Active Campaigns',
    description: 'View and manage your ongoing collaborations efficiently.'
  },
  {
    id: 'network-growth-hero',
    icon: FaUsers,
    title: 'Network Growth',
    description: 'Expand your professional connections seamlessly.'
  },
  {
    id: 'performance-metrics-hero',
    icon: FaChartLine,
    title: 'Performance Metrics',
    description: 'Track the success of your collaborations with detailed insights.'
  }
]

export const Hero = ({ user }: HeroProps) => {
  return (
    <section className="relative min-h-screen overflow-hidden bg-gradient-to-br from-blue-50 to-indigo-100 text-gray-800 dark:from-gray-900 dark:to-indigo-900 dark:text-white">
      <div
        className="absolute inset-0 bg-[url('/assets/hero-pattern.svg')] opacity-20"
        aria-hidden="true"
      ></div>
      <div className="container relative z-10 mx-auto px-6 py-24">
        <div className="mx-auto max-w-4xl text-center">
          <h1 className="mb-6 text-5xl font-bold leading-tight md:text-6xl">
            Welcome to Your <GradientText variant="blue-purple">Collaboration Hub</GradientText>
          </h1>
          <p className="mb-8 text-xl text-gray-600 dark:text-gray-300 md:text-2xl">
            Manage your connections, campaigns, and insights all in one place.
          </p>
          <div className="flex flex-col justify-center gap-4 sm:flex-row">
            {user ? (
              <Link
                href={`/profile/${user.id}`}
                className="inline-block rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 px-8 py-4 font-semibold text-white transition hover:from-blue-600 hover:to-indigo-700"
              >
                Go to Dashboard
              </Link>
            ) : (
              <Link
                href="/sign-in"
                className="inline-block rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 px-8 py-4 font-semibold text-white transition hover:from-blue-600 hover:to-indigo-700"
              >
                Sign In to Access Dashboard
              </Link>
            )}
            <Link
              href="/profiles"
              className="inline-block rounded-full bg-white px-8 py-4 font-semibold text-indigo-600 transition hover:bg-indigo-50 dark:bg-indigo-600 dark:text-white dark:hover:bg-indigo-700"
            >
              Explore Opportunities
            </Link>
          </div>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-8 text-center md:grid-cols-3">
          {heroFeatures.map(feature => {
            return (
              <FeatureCard
                key={feature.id}
                {...feature}
                className="bg-white/80 shadow-lg backdrop-blur-sm dark:bg-gray-800/80"
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}
