import { ApolloError } from '@apollo/client/errors'

/**
 * Defines different types of errors.
 */
export const ErrorType = {
  GRAPHQL_ERRORS: 'GRAPHQL_ERRORS',
  GRAPHQL_CLIENT_ERRORS: 'GRAPHQL_CLIENT_ERRORS',
  CLIENT_ERRORS: 'CLIENT_ERRORS',
  NETWORK_ERROR: 'NETWORK_ERROR'
} as const

/**
 * Determines the type of error.
 *
 * @param error - An instance of Error or ApolloError
 * @returns {ErrorType} - The type of the error
 */
export const getErrorType = (error: Error | ApolloError) => {
  if (!(error instanceof ApolloError)) {
    return ErrorType.CLIENT_ERRORS
  }

  const { graphQLErrors, clientErrors, networkError } = error

  if (graphQLErrors.length > 0) {
    return ErrorType.GRAPHQL_ERRORS
  }

  if (clientErrors.length > 0) {
    return ErrorType.GRAPHQL_CLIENT_ERRORS
  }

  if (networkError) {
    return ErrorType.NETWORK_ERROR
  }

  return ErrorType.CLIENT_ERRORS
}
