enum League {
  BRONZE,
  SILVER,
  GOLD,
  PLATINUM,
  DIAMOND,
  MASTER
}

const leagueThresholds = {
  BRONZE: 0,
  SILVER: 1000,
  GOLD: 2500,
  PLATINUM: 5000,
  DIAMOND: 10000,
  MASTER: 20000
}

export const determineLeague = (xp: number): League => {
  if (xp >= leagueThresholds.MASTER) {
    return League.MASTER
  }

  if (xp >= leagueThresholds.DIAMOND) {
    return League.DIAMOND
  }

  if (xp >= leagueThresholds.PLATINUM) {
    return League.PLATINUM
  }

  if (xp >= leagueThresholds.GOLD) {
    return League.GOLD
  }

  if (xp >= leagueThresholds.SILVER) {
    return League.SILVER
  }

  return League.BRONZE
}
