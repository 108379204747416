/**
 * Converts a string to Title Case, where the first letter of each word is capitalized,
 * and all other letters are in lowercase. Words are defined as sequences of alphanumeric characters separated by non-alphanumeric characters.
 *
 * @param {string} str - The string to convert to Title Case.
 * @returns {string} The string in Title Case.
 *
 * @example
 * toTitleCase("hello world"); // "Hello World"
 * @example
 * toTitleCase("javaScript language"); // "JavaScript Language"
 */
export function toTitleCase(str: string): string {
  return str
    .split(/(\s|-)/)
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

/**
 * Converts a string to snake case.
 *
 * @param {string} str - The string to convert.
 * @returns {string} The string in snake case.
 *
 * @example
 * toSnakeCase("Hello World"); // "hello_world"
 * @example
 * toSnakeCase("JavaScriptLanguage"); // "java_script_language"
 */
export function toSnakeCase(str: string): string {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => {
      return word.toLowerCase()
    })
    .join('_')
}

/**
 * Converts a string to kebab case.
 *
 * @param {string} str - The string to convert.
 * @returns {string} The string in kebab case.
 *
 * @example
 * toKebabCase("Hello World"); // "hello-world"
 * @example
 * toKebabCase("JavaScriptLanguage"); // "java-script-language"
 */
export function toKebabCase(str: string): string {
  return str
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .toLowerCase()
}

/**
 * Converts a string into a URL-friendly slug.
 *
 * @param {string} str - The string to convert into a slug.
 * @returns {string} The resulting slug.
 */
export function slugify(str: string): string {
  return str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+|-+$/g, '') // Trim - from start and end of text
}

/**
 * Capitalizes the first letter of a given string.
 * If the string is empty or starts with a non-alphabetic character, it returns the string unchanged.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 *
 * @example
 * capitalizeFirstLetter("hello world"); // "Hello world"
 * @example
 * capitalizeFirstLetter("2nd place"); // "2nd place"
 */
export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return str
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Truncates a string to a specified length, with an option to append ellipsis.
 *
 * @param {string} str - The string to truncate.
 * @param {number} maxLength - The maximum length of the truncated string.
 * @param {boolean} [ellipsis=true] - Flag to determine whether to append ellipsis (...) after truncation.
 * @returns {string} The truncated string.
 *
 * @example
 * truncate("Hello world", 5); // "Hello..."
 * @example
 * truncate("Hello world", 10, false); // "Hello worl"
 */
export function truncate(str: string, maxLength: number, ellipsis = true): string {
  if (str.length <= maxLength) {
    return str
  }

  const truncated = str.substring(0, maxLength)

  return ellipsis ? `${truncated}...` : truncated
}
