import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4-canary.19_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-02c0e82_gwlkz3qutaxldesbehc2s2xatu/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.4-canary.19_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-02c0e82_gwlkz3qutaxldesbehc2s2xatu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","error"] */ "/vercel/path0/node_modules/.pnpm/sonner@1.7.0_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c0e824-20241028__react@19.0.0-rc-02c0e824-20241028/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Features"] */ "/vercel/path0/src/app/[locale]/(site)/(home)/Features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/src/app/[locale]/(site)/(home)/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Newsletter"] */ "/vercel/path0/src/components/newsletter.tsx");
