/**
 * Formats a monetary amount as a string in a specified currency format, with options for further customization.
 *
 * @param {number} amount - The monetary amount in the smallest unit of the currency (like cents for USD).
 * @param {string} [currency='USD'] - The currency code to format the money in.
 * @param {string} [locale='en-US'] - The locale to use for formatting the currency.
 * @param {Intl.NumberFormatOptions} [customOptions={}] - Custom formatting options to override defaults.
 *
 * @returns {string} The formatted money string in the specified currency.
 */
export function formatMoney(
  amount: number,
  currency = 'USD',
  locale = 'en-US',
  customOptions: Intl.NumberFormatOptions = {}
): string {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  if (currency === 'JPY' && !customOptions.minimumFractionDigits) {
    defaultOptions.minimumFractionDigits = 0
    defaultOptions.maximumFractionDigits = 0
  }

  const finalOptions = { ...defaultOptions, ...customOptions }

  const formatter = new Intl.NumberFormat(locale, finalOptions)

  return formatter.format(amount / 100)
}
