export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  BACKSPACE: 8,
  TAB: 9,
  DELETE: 46
} as const
