// import { captureException } from '@sentry/nextjs'
import { toast } from 'sonner'

// import { getErrorType } from './getErrorType'

type Tags = {
  module: string
  action?: string
  title?: string
  showToast?: boolean
} & Record<string, string | number | boolean>

/**
 * Reports an error.
 *
 * @param error - The error to report
 * @param tags - Additional metadata to attach to the error
 */
export function reportError(error: string | Error, tags: Tags) {
  const errorObj = error instanceof Error ? error : new Error(error)

  console.error('reportError: ', errorObj, tags)

  if (tags.showToast) {
    toast.error(errorObj.message, {
      duration: 4000,
      style: {
        // You can use Tailwind classes here
        // Ensure you have @tailwindcss/forms plugin installed to use these classes
        borderRadius: '5px',
        background: '#333',
        color: '#fff',
        zIndex: 1000
      }
    })
  }

  // const newTagsValue = {
  //   ...tags,
  //   errorType: getErrorType(errorObj)
  // }

  // For the time being, we do want to report errors in development and also show the toast, we can rethink this later

  // if (process.env.NODE_ENV !== 'development') {
  //   delete newTagsValue.showToast;
  //   captureException(errorObj, { tags: newTagsValue });
  // }

  // captureException(errorObj, { tags: newTagsValue })
}
