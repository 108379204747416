'use client'

import { FaChartLine, FaComments, FaHandshake, FaShieldAlt, FaUserCheck } from 'react-icons/fa'

import type { FeatureCardProps } from '~/components/FeatureCard'
import { FeatureCard } from '~/components/FeatureCard'
import { GradientText } from '~/components/GradientText'

const features: FeatureCardProps[] = [
  {
    id: 'seamless-onboarding',
    icon: FaUserCheck,
    title: 'Seamless Onboarding',
    description: 'Get started quickly with our intuitive onboarding process tailored to your role.'
  },
  {
    id: 'verified-profiles',
    icon: FaShieldAlt,
    title: 'Verified Profiles',
    description: 'Build trust with verified profiles through social media integration.'
  },
  {
    id: 'integrated-chat',
    icon: FaComments,
    title: 'Integrated Chat',
    description: 'Communicate seamlessly with real-time messaging and collaboration tools.'
  },
  {
    id: 'secure-payments',
    icon: FaHandshake,
    title: 'Secure Payments',
    description: 'Handle transactions confidently with our secure payment integration.'
  },
  {
    id: 'analytics-insights',
    icon: FaChartLine,
    title: 'Analytics & Insights',
    description: 'Gain valuable insights with integrated analytics for better decision-making.'
  }
]

export const Features = () => {
  return (
    <section
      id="features"
      className="relative bg-gradient-to-br from-purple-50 to-blue-50 py-20 dark:from-gray-900 dark:to-blue-900"
    >
      <div
        className="absolute inset-0 bg-[url('/assets/grid.svg')] opacity-10"
        aria-hidden="true"
      ></div>
      <div className="container relative z-10 mx-auto px-6">
        <h2 className="mb-12 text-center text-4xl font-bold">
          <GradientText variant="purple-blue">Features</GradientText>
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {features.map(feature => {
            return <FeatureCard key={feature.id} {...feature} />
          })}
        </div>
      </div>
    </section>
  )
}
