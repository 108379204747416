import type { IconType } from 'react-icons'

import { GradientText } from '~/components/GradientText'

import { cn } from '../UI-Temp/src/utils'

export type FeatureCardProps = {
  id: string
  icon: IconType
  title: string
  description: string
  className?: string
}

export const FeatureCard = ({ icon: Icon, title, description, className }: FeatureCardProps) => {
  return (
    <div
      className={cn(
        'rounded-lg border-feature-card-border bg-feature-card-bg p-6 shadow-lg transition-shadow duration-300 hover:shadow-xl',
        className
      )}
    >
      <div className="mb-4 text-center">
        {/* Apply gradient directly to the icon container */}
        <div className="inline-block rounded-full bg-gradient-radial from-purple-400 to-blue-500 p-3">
          <Icon className="text-feature-card-icon mx-auto text-3xl" aria-hidden="true" />
        </div>
      </div>
      <h3 className="mb-2 text-center text-xl font-semibold text-feature-card-title">
        <GradientText variant="indigo-purple" direction="to-r">
          {title}
        </GradientText>
      </h3>
      <p className="text-center text-feature-card-description">{description}</p>
    </div>
  )
}
