export { cn } from './cn'
export { determineLeague } from './determineLeague'
export { publish, subscribe, unsubscribe } from './events'
export { formatBytes } from './formatBytes'
export { formatDate } from './formatDate'
export { formatMoney } from './formatMoney'
export { getErrorType } from './getErrorType'
export { isServer } from './isServer'
// export { createMetadata } from './metadata'
export { absoluteUrl } from './absoluteUrl'
export { isBlink, isChrome, isEdge, isFirefox, isIE, isOpera, isSafari } from './browserUtils'
export { getHostname, getSecondLevelDomain, getTopLevelDomain } from './domainUtils'
export {
  calculateDiscountPercentage,
  isAlmostOutOfStock,
  isAvailable,
  isNewArrival,
  isOnSale
} from './e-commerce'
export { getRelativeTime } from './getRelativeTime'
export { isEmpty } from './isEmpty'
export { KEY_CODES } from './keyCodes'
export { missingClass } from './missingClass'
export {
  bounce,
  fadeIn,
  fadeOut,
  jiggle,
  pulse,
  scaleDown,
  scaleUp,
  shake,
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
  slideInFromTop,
  swing
} from './motion'
export { addParameter, hasParameter, stripParameter } from './paramUtils'
export { reportError } from './reportError'
export {
  getNestedPropertyValue,
  sortAtoZ,
  sortByMostDiscounted,
  sortByNewest,
  sortByOldest,
  sortByPriceHighToLow,
  sortByPriceLowToHigh,
  sortBySale,
  sortZtoA
} from './sortingUtils'
export {
  capitalizeFirstLetter,
  slugify,
  toKebabCase,
  toSnakeCase,
  toTitleCase,
  truncate
} from './stringUtils'
export { getAllUtmParameters } from './utmUtils'
